import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import DealFlowEmptyIllustration from 'assets/illustrations/deal-flow-zero-results2.svg';

import { ListScreenLayoutEmpty } from 'components/ListScreenLayout';

import { routePaths } from 'router/routePaths';

export const DealFlowListEmpty = () => {
  const { t } = useTranslation();

  const enableWithLoans = false;

  const withLoans = (
    <ListScreenLayoutEmpty.Wrapper data-testid="deal-flow-list-empty">
      <img src={DealFlowEmptyIllustration} alt={t('DealFlowListEmpty:title')} />
      <ListScreenLayoutEmpty.Title>
        {t('DealFlowListEmpty:title')}
      </ListScreenLayoutEmpty.Title>
      <ListScreenLayoutEmpty.Subtitle>
        {t('DealFlowListEmpty:subtitle')}
      </ListScreenLayoutEmpty.Subtitle>
      <ListScreenLayoutEmpty.ActionContainer>
        <Button as={Link} to={routePaths.dealFlowAddTargetBusiness}>
          {t('DealFlowListEmpty:action')}
        </Button>
      </ListScreenLayoutEmpty.ActionContainer>
      <ListScreenLayoutEmpty.SecondaryActionContainer>
        {t('DealFlowListEmpty:or')}{' '}
        <Link to={routePaths.forSale}>
          {t('DealFlowListEmpty:explore_for_sale')}
        </Link>
      </ListScreenLayoutEmpty.SecondaryActionContainer>
    </ListScreenLayoutEmpty.Wrapper>
  );

  const withoutLoans = (
    <ListScreenLayoutEmpty.Wrapper data-testid="deal-flow-list-empty">
      <img src={DealFlowEmptyIllustration} alt={t('DealFlowListEmpty:title')} />
      <ListScreenLayoutEmpty.Title>
        {t('DealFlowListEmpty:title_no_loans')}
      </ListScreenLayoutEmpty.Title>
      <ListScreenLayoutEmpty.Subtitle>
        {t('DealFlowListEmpty:subtitle_no_loans')}
      </ListScreenLayoutEmpty.Subtitle>
      <ListScreenLayoutEmpty.ActionContainer>
        <Button as={Link} to={routePaths.forSale}>
          {t('DealFlowListEmpty:explore_for_sale_no_loans')}
        </Button>
      </ListScreenLayoutEmpty.ActionContainer>
    </ListScreenLayoutEmpty.Wrapper>
  );

  return enableWithLoans ? withLoans : withoutLoans;
};
