import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SubNavigationMenu } from 'ui/SubNavigationMenu';

import { QualificationStatusAccountBadge } from 'components/QualificationStatus';

import { routePaths } from 'router/routePaths';

export const AccountScreenMenu = () => {
  const { t } = useTranslation();
  const enableInvestmentCriteria = false;

  return (
    <SubNavigationMenu.Menu>
      <SubNavigationMenu.MenuItem
        icon={<FontAwesomeIcon icon={regular('user')} size="lg" />}
        as={NavLink}
        to={routePaths.accountQualification}
        extraChildren={<QualificationStatusAccountBadge />}
      >
        {t('AccountScreen:your_qualification_menu')}
      </SubNavigationMenu.MenuItem>
      <SubNavigationMenu.MenuItem
        icon={<FontAwesomeIcon icon={regular('bell')} size="lg" />}
        as={NavLink}
        to={routePaths.accountNotifications}
      >
        {t('AccountScreen:notifications_title')}
      </SubNavigationMenu.MenuItem>
      {enableInvestmentCriteria && (
        <SubNavigationMenu.MenuItem
          icon={<FontAwesomeIcon icon={regular('briefcase')} size="lg" />}
          as={NavLink}
          to={routePaths.accountInvestmentCriteria}
        >
          {t('AccountScreen:investment_thesis_title')}
        </SubNavigationMenu.MenuItem>
      )}
    </SubNavigationMenu.Menu>
  );
};
