import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { PageHeader } from 'ui/PageHeader';

import { ListScreenLayout } from 'components/ListScreenLayout';
import { usePathWithExitPath } from 'components/PortfolioApplication/ApplicationExitPath';
import { PortfolioList } from 'components/PortfolioList';
import { usePortfolioList } from 'components/PortfolioList/PortfolioList.hooks';
import { AnimatedScreenOpacityWrapper } from 'components/ScreenLayout';

import { routePaths } from 'router/routePaths';

export const PortfolioListScreen = () => {
  const { t } = useTranslation();
  const title = t('PortfolioListScreen:title');

  const { isEmpty } = usePortfolioList();

  const { to, state } = usePathWithExitPath(
    routePaths.portfolioApplication.addBusiness
  );

  const enableListBusiness = false;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AnimatedScreenOpacityWrapper data-testid="portfolio-list-screen">
        <ListScreenLayout.Wrapper>
          <PageHeader
            title={title}
            action={
              !isEmpty &&
              enableListBusiness && (
                <Button
                  variant="primary"
                  as={Link}
                  to={to}
                  state={state}
                  data-testid="add-business-button-action"
                >
                  {t('PortfolioListScreen:add_business_action')}
                </Button>
              )
            }
          />
          <ListScreenLayout.List>
            <PortfolioList />
          </ListScreenLayout.List>
        </ListScreenLayout.Wrapper>
      </AnimatedScreenOpacityWrapper>
    </>
  );
};
